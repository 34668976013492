//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {sortArray} from "~/helpers/arraySorts.js";
import {UserMixin} from "../node_modules/sitis-nuxt/lib/mixins";
import Loader from "~/components/loader/Loader.vue";
import ssrReplaceText from "~/helpers/ssrReplaceText";
import YandexMetricaMixin from "~/mixin/YandexMetricaMixin";
import LinkToAccount from "~/components/page/LinkToAccount.vue";
import Breadcrumbs from "../components/page/PageBreadcrumbs.vue";
import getSelectedCountryId from "~/helpers/getSelectedCountryId";
import LanguagesList from "~/components/profile/LanguagesList.vue";
import HelpDisabledRuCountry from "~/components/common/HelpDisabledRuCountry.vue"
import {checkCmsPageFromPath} from "~/helpers/pageIndexHelper";
import utilsGetOgUrl from "~/helpers/utilsGetOgUrl";

export default {
  name: "MenuCmsPage",
  mixins: [UserMixin, YandexMetricaMixin],
  layout: "custom-layout",

  head() {
    if (this.service) {
      const service = this.service;
      const locale = this.$i18n?.locale || 'en';
      const seoDescription = ssrReplaceText(String(this.$store.state.appState?.vars?.[`${ locale }_Страница сервиса_meta description`] || ""), {
        search: "{name}",
        replace: service?.name
      })

      const head = {
        title: service?.seo_title || "Grizzly SMS",
        meta: [
          {
            hid: "metadescription",
            name: "description",
            content: service?.seo_description || seoDescription || "",
          },
          {name: "keywords", content: service?.seo_keywords || ""},
          {
            hid: "og:title",
            name: "og:title",
            property: "og:title",
            content: service?.seo_title || "Grizzly SMS",
          },
          {
            hid: "og:site_name",
            name: "og:site_name",
            property: "og:site_name",
            content: "GrizzlySMS",
          },
          {
            hid: "og:description",
            name: "og:description",
            property: "og:description",
            content: service?.seo_description || seoDescription || "",
          },
          {
            hid: "apple-mobile-web-app-title",
            name: "apple-mobile-web-app-title",
            content: `apple-${service?.seo_title || ""} `,
          },
          {
            hid: "og:image",
            name: "og:image",
            property: "og:image",
            content: service?.icon || 'logo',
          },
          {
            hid: "og:url",
            name: "og:url",
            property: "og:url",
            content: utilsGetOgUrl({ context: this }),
          },
        ],
      };

      return head;
    }
    return {};
  },

  data() {

    return {
      localeServicesCountry: null,

      isPageReload: false,
      isLoading: false,
      currentNumbers: [],
      countrysList: [],
      currentCountry: {
        external_id: "",
        name: "",
        icon: "",
      },
    };
  },

  async asyncData({$api, route, params, redirect, localePath, store, i18n }) {
    const apiMenus = store?.state?.cms?.menus || {};

    const locale = i18n?.locale || i18n?.defaultLocale;
    let fullPath = route.path === '/' ? '' : route.path;
    fullPath = String(fullPath || '').split(`/${ locale }`).pop() || '';

    let typeCurrentPage = "service";
    if (checkCmsPageFromPath(fullPath || '', apiMenus)) {
      typeCurrentPage = "cms";
    }

    let resService = null;
    let slug = params.pathMatch;
    let isCountresLoaded = false;
    if (typeCurrentPage === 'service') {
      resService = await $api.agent.transport
        .get(`service/get-by-slug/${slug}?expand=accountAvailable,tagsArticles`)
        .catch((err) => {});
    }

    let service = resService?.data || null;
    if (!!service) {
      const pricesService = await $api.agent.transport
        .get(`service/get-prices-count-for-service/${service.external_id}`)
        .then((res) => {
          return res.data || 0;
        })
        .catch((e) => {
          return 0;
        });
    }

    let countrysList = [];
    if (service?.external_id) {
      const countrys = await $api.agent.transport
        .get(`country/active-by-service/${service?.external_id}`)
        .then(res => {
          return res.data;
        })
        .catch((err) => {
          return [];
        });
      countrysList = sortArray(countrys);
    }
    isCountresLoaded = true;

    return {
      service,
      countrysList,
      isCountresLoaded,
      favoriteCountries: (service?.favorite_countries || [])
    };
  },

  watch: {
    "$store.state.services.services": function () {
      const services = this.$store?.state?.services?.services || [];
      if (
        (this.localeServicesCountry || []).length <= 0 &&
        services.length > 0
      ) {
        this.localeServicesCountry = services;
      }
    },
  },

  async mounted() {
    const authToken = this.$cookies.get('authToken');
    if (authToken) {
      await this.getUser();
    }

    // Получение и устоновка активной страны
    let currentCountryDefoultId = this.$defaultCountrySelection();
    let currentCunrtyId = getSelectedCountryId({self: this}) || currentCountryDefoultId;

    const country = this.countrysList.find((t) => +t.external_id == +currentCunrtyId);
    if (!!country) {
      this.currentCountry = country;
    }
    if (!country) {
      const firstCountry = this.countrysList?.[0];
      if (!!firstCountry) {
        this.currentCountry = firstCountry;
        await this.getPriceFromCurrentCounrty(firstCountry);
      }
    }

    // -------------------------------------

    // Скролл в шапку сайта
    if (Object.keys(this.service || {}).length > 0) {
      document.getElementById("scroll-container").scrollTo({
        top: 0,
        behavior: "instant",
      });
    }
    // --------------------


    (async () => {
      await this.getPricesFavoriteCountries();
    })();
  },

  components: {
    LinkToAccount,
    Loader,
    Breadcrumbs,
    LanguagesList,
    HelpDisabledRuCountry,

    TagsArticles: () => import("~/components/service/TagsArticles.vue"),
    CustomMultiselect: () => import("~/components/common/CustomMultiselect.vue"),
  },

  methods: {
    messageDisabledCard(favoriteCountries) {
      if (!favoriteCountries?.button_status) {
        return this.$t("servicesList.numberTemporarilyUnavailable");
      }
    },

    getNumberCount(numberCount = 0) {
      const count = numberCount || 0;
      if (count <= 0) {
        return this.$t('servicesList.quantityFew')
      }

      return count;

      // || createRandomNumber(100, 1000, 10)
    },

    async getPriceFromCurrentCounrty(event) {
      // Получение активного кода страницы
      let user = this?.user?.id ? this?.user?.id : 'guest';
      if (!this?.user?.dynamicFields?.custom_price) {
        user = 'guest';
      }

      let currentCountryDefoultId = this.$defaultCountrySelection();

      let currentCountry =
        event?.external_id || this.currentCountry?.external_id;

      currentCountry = Boolean(
        typeof currentCountry === "undefined" || currentCountry === null
      )
        ? String(currentCountryDefoultId)
        : String(currentCountry);

      currentCountry = +currentCountry;

      // Получение списка локальный услуг по выбранной стране
      const data = await this.$api.agent.transport
        .get(`service/get-prices/${currentCountry}?user=${user}`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return [];
        });

      this.localeServicesCountry = data;

    },

    async onBuyNumber(custonCountryId = null, numberType = '') {
      const paymentTest = this.$cookies.get("payment-test");

      if (!this.isLoggedIn && paymentTest) {
        await this.$router.push(this.localePath(`/profile/pay?service=${this?.service?.external_id}&country=${custonCountryId || this?.currentCountry?.external_id}`));
        return;
      }

      if (!this.isLoggedIn && !paymentTest) {
        await this.$router.push(this.localePath("/registration?goback=1"));
        return null;
      }

      // Проверка баланса пользователя
      const balance = await this.$api.agent.transport.get("sms-users/balance")
        .then((res) => {
          return res.data
        }).catch(() => {
          return 0
        });

      if (paymentTest && balance < +this.getCurrentServiceInfo?.[0].price) {
        await this.$router.push(this.localePath(`/profile/pay?service=${this?.service?.external_id}&country=${custonCountryId || this?.currentCountry?.external_id}`));
        return;
      }

      if (!paymentTest && balance < +this.getCurrentServiceInfo?.[0].price) {
        await this.$router.push(this.localePath("/profile/pay"));
        return
      }

      numberType === 'country' ?
        this.yandexGoalCheck('buy_service-country_attempt') :
        this.yandexGoalCheck('buy_service-main_attempt');

      this.yandexSendUserInfo({
        userId: this?.user?.id,
        userEmail: this?.user?.email
      })

      this.isLoading = true;
      let countryId = custonCountryId || this?.currentCountry?.external_id;
      let numberId = this?.service?.external_id;
      const newNumber = await this.$api.agent.transport
        .get(`sms-users/get-number/${countryId}/${numberId}`)
        .catch((err) => {
          let error = JSON.parse(err?.request?.response);
          this.$toast.error(error.message || "Error");
          this.isLoading = false;
        });
      if (!newNumber) {
        this.isLoading = false;
        return null;
      }

      if (this.$route.path !== "/profile") {
        this.$router.push(this.localePath("/profile"));
      }

      numberType === 'country' ?
        this.yandexGoalCheck('buy_service-country_success') :
        this.yandexGoalCheck('buy_service-main_success');


      this.yandexSendUserID({
        userId: this?.user?.id,
      })

      this.isLoading = false;
      this.$store.commit("active-numbers/updateIndex");
    },

    getPricesFavoriteCountries: async function () {
      let user = this?.user?.id ? this?.user?.id : 'guest';
      if (!this?.user?.dynamicFields?.custom_price) {
        user = 'guest';
      }
      if (user === 'guest') {
        return
      }

      let favoriteCountries = [...this.favoriteCountries];

      const slugService = this.$route?.params?.pathMatch || "";
      await Promise.all(favoriteCountries.map(async (country, index) => {
        const price = await this.$api.agent.transport.get(`/service/get-prices/${country.external_id}?user=${user}`).then((res) => {
          return (res.data || []).find((t) => t.service_slug === slugService)?.price || '-'
        }).catch(() => {
          return '-'
        });
        favoriteCountries[index]['price'] = price;
      }));

      this.favoriteCountries = favoriteCountries;
    }
  },

  computed: {
    user() {
      return this.$store.getters['user/user'] || null;
    },

    isDisable() {
      const country = this.currentCountry;
      const service = this.service;

      const yemeksepeti = Boolean(
        +country?.external_id === 62 &&
        service.external_id === "yi"
      );

      return yemeksepeti;
    },

    getCurrency() {
      return this.$store.getters["currency/currentCurrency"];
    },
    getBreadcrumbs() {
      const breadcrumbs = [
        {path: "/", title: this.$t("breadcrumbs.homePage")},
        {path: this.localePath('/services'), title: this.$t("partner.price_regulator.services")},
        {path: this.$route.path, title: this?.service?.name || ""},
      ];
      return breadcrumbs;
    },

    getCurrentServiceInfo() {
      let serviceList =
        this.localeServicesCountry ||
        this.$store?.state?.services?.services ||
        [];

      let currentService = serviceList.find(
        (t) => t.service_external_id == this.service.external_id
      );

      return [currentService];
    },

    path() {
      return this.$route.path === "/" ? "homepage" : this.$route.path;
    },

    isDisableService: function () {
      return false;

      return Boolean(
        +this.currentCountry?.external_id === 0 &&
        this.service.external_id === "wa"
      );
    },


    serviceFavoriteCountries: function () {
      return this.service?.favorite_countries || []
    },

    versionLanguageService: function () {
      return this.$nuxt?.context?.env?.versionLanguageService || '0'
    },

    isMobileTemplate: function () {
      return Boolean(this.$store.state["local-settings"]?.viewport === 'mobile');
    },
    isHiddenLinkToAccount: function () {
      const locale = this.$i18n.locale;
      return Boolean(locale === 'ru')
    },
  },
};
